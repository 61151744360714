import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	CardText
} from "reactstrap";


const AutoSuggestSearch = () => {
	const [searchTerm, setSearchTerm] = useState('');
	const [suggestions, setSuggestions] = useState({});
	const timerRef = useRef(null);

	useEffect(() => {
	// This useEffect hook will be triggered whenever searchTerm changes
	clearTimeout(timerRef.current);

	// Set up a new timer to fetch suggestions after 1 second (you can adjust the delay)
	timerRef.current = setTimeout(() => {
		fetchSuggestions(searchTerm);
	}, 1000);

	// Cleanup function to clear the timer when the component unmounts or when searchTerm changes
	return () => {
		clearTimeout(timerRef.current);
	};
	}, [searchTerm]);

	const fetchSuggestions = async (value) => {
	try {
		const response = await axios.get(`http://localhost:8000/api/autosuggest/?term=${value}`);
		setSuggestions(response.data);
	} catch (error) {
		console.error('Error fetching autosuggestions:', error);
	}
	};

	// ...

return (
	<Container>
		<Row className="justify-content-center">
		<Col md="6">
			<div className="text-center">
			<input
				type="text"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				placeholder="Search..."
				className="form-control"
			/>
			</div>
			<div>
			{Object.entries(suggestions).length === 0 ? (
				<p>No suggestions found.</p>
			) : (
				Object.entries(suggestions).map(([term, items], key) => (
				<div key={key}>
					<Card style={{ boxShadow: '0px 5px 25px 0px rgba(0, 0, 0, 0.2)' }}>
					<CardBody>
						<CardTitle tag="h5">{term}</CardTitle>
						<ul style={{ listStyleType: 'none', padding: 5 }}>
						{items.map(({ question, answer }, index) => (
							<li key={index} style={{ marginBottom: '10px' }}>
							<CardText>
								<strong style={{ color: 'blue' }}>Question:</strong> {question} <br />
								<strong style={{ color: 'green' }}>Answer:</strong> {answer}
							</CardText>
							</li>
						))}
						</ul>
					</CardBody>
					</Card>
				</div>
				))
			)}
			</div>
		</Col>
		</Row>
	</Container>
	);
};

export default AutoSuggestSearch;
