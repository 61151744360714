import React, { Component } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

class AdminLogin extends Component {
	constructor(props) {
		super(props);
		this.state = { navigate: null}
	}

	state = {
		username: '',
		password: '',
		error: '',
	};

	handleInputChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleAdminLogin = (event) => {
			event.preventDefault();
			const { username, password } = this.state;

			const formData = new FormData();
			formData.append('username', username);
			formData.append('password', password);

			axios
				// .post('http://localhost:8000/login', formData)
				.post('/api/login', formData)
				.then((response) => {
					if (response.data.message === 'Admin login successful') {
						this.setState({navigate: "/upload"})
					} else {
						this.setState({ error: 'Admin login failed. Please check your credentials.' });
					}
				})
				.catch((error) => {
					console.error('Error logging in:', error);
				});
	};

	render() {
		if (this.state.navigate) {
			return <Navigate to={this.state.navigate}/>
		}
		return (
			<div className="admin-login-container">
				<h1>Admin Login</h1>
				<form onSubmit={this.handleAdminLogin}>
					<div>
						<label>Username:</label>
						<input
							type="text"
							name="username"
							value={this.state.username}
							onChange={this.handleInputChange}
						/>
					</div>
					<div>
						<label>Password:</label>
						<input
							type="password"
							name="password"
							value={this.state.password}
							onChange={this.handleInputChange}
						/>
					</div>
					<button type="submit">Login</button>
				</form>
				{this.state.error && <p className="error-message">{this.state.error}</p>}
			</div>
		);
	}
}

export default AdminLogin;
