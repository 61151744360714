import React from "react";
import {
	Container,
	Row,
	Col,
	Carousel,
	CarouselItem,
	CarouselIndicators
} from "reactstrap";
  
// Founding fathers
const items = [
	{
	  src: require("assets/img/logo/logo_gem_asd.png"),
	  altText: "Gemeente Amsterdam",
	  caption: ""
	},
	{
	  src: require("assets/img/logo/logo_ams.png"),
	  altText: "Amsterdam Institute for Advanced Metropolitan Solutions",
	  caption: ""
	},
];
  
export const FoundingFathers = () => {
	return (
		<div className="section section-father text-center">
			<Container>
				<h2 className="title">Partners & Sponsors</h2>
				<div className="father">
					<Row>
						<Col md="4">
							<div className="founding-father">
								<img
								alt="..."
								className="img-founding-father"
								src={require("assets/img/logo/logo_ams.png")}
								></img>
								<h4 className="title">Amsterdam Institute for Metropolitan Advanced Solutions</h4>
								<p className="category text-info">Founding Father</p>
								<p className="description">
									Room for details
								</p>
							</div>
						</Col>
						<Col md="4">
							<div className="founding-father">
								<img
								alt="..."
								className="img-founding-father"
								src={require("assets/img/logo/logo_gem_asd.png")}
								></img>
								<h4 className="title">Gemeente Amsterdam</h4>
								<p className="category text-info">Founding Father</p>
								<p className="description">
								  	Room for details
								</p>
							</div>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	)
}

export default FoundingFathers;


