
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";

import AmsPage from "views/AmsPage";
import AdminLogin from "views/AdminLogin";
import FileUploadForm from "components/Admin/Upload";
import {Navigation} from './components/navigation';
import { Login } from "components/Login/Login";
import EditPage from "components/Admin/Edit";

import './interceptor/axios_interceptor';
import { Logout } from "components/Login/Logout";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<BrowserRouter>
	<Navigation></Navigation>
		<Routes>
		<Route path ="/" element={<AmsPage />} />
		<Route path ="/login_admin" element={<AdminLogin />} />
		<Route path ="/login" element={<Login />} />
		<Route path ="/logout" element={<Logout />}/>
		<Route path ="/upload" element={<FileUploadForm />}/>
		<Route path ="/edit" element={<EditPage />}/>
		</Routes>
	</BrowserRouter>
);
