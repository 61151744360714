import React, { useState, useEffect } from 'react';
import axios from 'axios';

const FileUploadForm = () => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [loading, setLoading] = useState(false);


	const handleFileChange = (e) => {
		setSelectedFile(e.target.files[0]);
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData();
		formData.append('file', selectedFile);

		try {
			await axios.post('/api/upload', formData);
			alert('File uploaded, please reload the page to see the file in the file list');
		} catch (error) {
			console.error('Error uploading file:', error);
		} finally {
			setLoading(false);	
		}
	};

	// FIXME: add protection for empty input

	if (localStorage.getItem('access_token') === null) {
		window.location.href = '/login';
	}

	return (
		<div>
			<h1>Upload a File</h1>
			<form onSubmit={handleFormSubmit}>
				<input
					type="file"
					name="file"
					onChange={handleFileChange}
				/>
				<button type="submit" disabled={loading}>
					{loading ? 'Uploading...' : 'Upload File'}
				</button>
			</form>
			<FileList />
		</div>
	);
};

const FileList = () => {
	const [files, setFiles] = useState([]);

	useEffect(() => {
		fetchFileList();
	}, []);

	const fetchFileList = () => {
		axios.get('/api/list_files')
			.then(response => {
				setFiles(response.data);
			})
			.catch(error => {
				console.error('Error fetching file list:', error);
			});
	};

	const handleDeleteFile = (filename) => {
		const isConfirmed = window.confirm(`Are you sure you want to delete the file "${filename}"?`);
		if (isConfirmed) {
			axios.delete(`/api/delete_file/${filename}`)
				.then(response => {
					console.log('File deleted:', response.data?.message);
					fetchFileList();
				})
				.catch(error => {
					console.error('Error deleting file:', error);
				});
		}
	};

	return (
		<div>
			<h1>File List</h1>
			<ul>
				{files.map(filename => (
					<li key={filename}>
						{filename}
						<button onClick={() => handleDeleteFile(filename)}>Delete</button>
					</li>
				))}
			</ul>
		</div>
	);
};

export default FileUploadForm;
