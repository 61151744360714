import React, { useState } from 'react';
import { RotatingSquare } from	'react-loader-spinner'



const SearchBar = ({ onSearch }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [loading, setLoading] = useState(false);

	const handleInputChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const handleSearch = async () => {
		setLoading(true);
		await onSearch(searchTerm);
		setLoading(false)
	};

	return (
	<div className="text-center">
		<input
		type="text"
		value={searchTerm}
		onChange={handleInputChange}
		onKeyDown={(e) => {
			if (e.key === 'Enter') {
				handleSearch();
			}
		}}
		placeholder="Voer uw keyword(s) in en toets 'ENTER'"
		style={{
			width: '70%',
			padding: '1vw',
			color: '#228B22',
			boxShadow: '4px 6px 28px 5px rgba(0,100,0,0.6)',
			border: '0 solid transparent',
			borderRadius: '4vw',
			// height: '10vh',
			fontSize: '1.5vw',
		}}
		/>
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			{ loading ?
				<RotatingSquare
				height="100"
				width="100"
				color="#4fa94d"
				ariaLabel="rotating-square-loading"
				strokeWidth="4"
				wrapperStyle={{}}
				wrapperClass=""
				visible={true} 
				/> 
				:
				null 
			}
		</div>
	</div>
	);
};

export default SearchBar;