import axios from 'axios';
import React, { Component } from 'react';

class ForwardQuestion extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			question: props.steps.question.value,
			answer:'',
			score: '',
			seconds_passed: 0,
		};
	}

	state_toggle_view = {
	opened: false
	}

	// questionBackend = async () => {
	// 	const question = this.state.question;
	// 	this.setState({ loading: true });
	// 	const startTime = Date.now();

	// 	console.log('keyword question', this.state.question)

	// 	try {
	// 		const response = await axios.post('http://127.0.0.1:8000/question_answer', {
	// 			question: question,
	// 		});
	// 		const answer_data = JSON.parse(response.data)
	// 		const score = answer_data.score * 100
	// 		const fixed_score = score.toFixed(1)

	// 		this.setState({ answer: answer_data.answer });
	// 		this.setState({ score: fixed_score });
	// 		this.setState({ loading: false });
	// 	} catch (error) {
	// 		console.error('Error sending question to backend:', error);
	// 	}
		
	// 	const endTime = Date.now();
	// 	const secondsPassed = Math.floor((endTime - startTime) / 1000);
	// 	this.setState({ secondsPassed });
	// 	this.props.triggerNextStep();
	// };

	// toggleFloating = ({ opened }) => {
	// this.setState({ opened }); 
	// }

	// componentDidMount() {
	// 	this.questionBackend();
	// }

	render() {
		return ( 
			<div>
				PLACEHOLDER voor email input
			</div>
		// const { question, answer, loading, secondsPassed, score } = this.state;
		// return loading ? (<div>Loading...</div>) : (
			// <div style={{ width: '100%' }}>
			// 	<p style={{ fontSize: '14px', color: 'black' }}>
			// 		{answer}
			// 	</p>
			// 	<p style={{ fontSize: '12px', color: 'black', fontStyle: 'italic' }}>
			// 		DEV: gevonden in {secondsPassed} seconden {/* // TODO: remove */}
			// 		Onze AI is {score}% zeker dat dit het juiste antwoord is.	
			// 	</p>
			// 	<p style={{ fontSize: '12px', color: 'gray' }}>
			// 		Disclaimer: dit antwoord is verkregen uit documentatie door middel van kunstmatige intelligentie en kan mogelijk onjuist zijn.
			// 	</p>
			// </div>
		);
	}
}

export default ForwardQuestion;
