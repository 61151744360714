import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import AMSChatBot from "components/Bot/bot_form";
import AutoSuggestSearch from "./autosuggest";

const ProjectInformation = () => {
	const [isCollapsed, setIsCollapsed] = useState(true);

	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed);
	}

	return (
	<>	
		<div className="section section-about-us"></div>
		<Container>
			<Row>
				<Col className="ml-auto mr-auto text-center" md="12">
					<p style={{ fontSize: '20px' }}>
						<b>
						Welkom op onze onderzoeksprojectwebsite! Hier vindt u algemene informatie over het project, daarnaast kunt u het zoekveld gebruiken om direct specifieke en relevante 
						informatie te ontdekken door keywords in te voeren. Versterk uw begrip van het project met moeiteloze toegang tot 
						wetenschappelijk erkende documenten.
						</b>
					</p>
				</Col>
			</Row>	
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
			  <h5 onClick={toggleCollapse} style={{ cursor: 'pointer'}}>
			<u>Klik hier</u> voor meer algemene informatie over het project.
			</h5>
				{!isCollapsed && (

					<p>
					Ontdek de toekomst van voedselafvalverwerking met het Project Proefopstelling Voedselrestenvermalers (PPV). Verschillende gemeentes hebben 
					gekozen voor een verdere uitwerking van onderzoek naar een inzamelmethode voor groente, fruit en etensresten (GFE) met behulp van 
					voedselrestenvermalers. Dit project biedt niet alleen efficiëntie en circulaire voordelen, maar daarnaast beperkt het de noodzakelijke 
					inzamelcapaciteit voor GFT. Om dit te bereiken voeren we experimenten uit om de ideale instellingen en specificaties voor onze systeemonderdelen 
					te achterhalen, met een focus op het bereiken van het meest circulaire resultaat binnen haalbare logistieke en financiële kaders. Daarnaast 
					geven we demonstraties van het complete systeem om projectontwikkelaars, gemeentebesturen en andere belanghebbenden gerust te stellen, eventuele 
					bedenkingen weg te nemen en praktische vragen te beantwoorden. 
                </p>
			  	)}
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/food_waste_sink.jpeg") + ")"
                    }}
                  >
                    {/* <p className="blockquote blockquote-info">
                      "Quisque nunc risus, rhoncus sed massa a, mollis sollicitudin 
					  augue. Vestibulum rutrum semper dapibus." <br></br>
                      <br></br>
                      <small>-Someone</small>
                    </p> */}
                  </div>
				  {!isCollapsed && (
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
					  "url(" + require("assets/img/waste_to_food.jpeg") + ")"
                    }}
					></div>
				  )}
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
						backgroundImage:
                        "url(" + require("assets/img/food_grinder.jpeg") + ")"
                    }}
					>
					</div>
									{!isCollapsed && (
					<div>
                  <h3>
                   Proefopstelling
                  </h3>
                  <p>
				  	Om de experimenten uit te kunnen voeren, wordt een proefopstelling gebouwd op het terrein van het AMS Institute in Amsterdam. Hier wordt 
					een realistische testomgeving gecreëerd om de nieuwe methode van inzameling uit te testen. Met een demontabel ontwerp is zelfs een tijdelijke 
					verplaatsing naar andere deelnemende gemeenten mogelijk. AMS Institute leidt de organisatie en coördinatie van het project, waarbij gemeenten 
					en Rijkswaterstaat als gezamenlijke opdrachtgevers optreden. 
                  </p>
				  <h3>
					Duurzaamheid
				  </h3>
                  <p>
					Het inzamelingsconcept, gebaseerd op het "eenvoudig en flexibel" ontwerp, belooft een duurzame oplossing voor de inzameling van huishoudelijke 
					groente-, fruit- en etensresten. Met een gescheiden buizenstelsel en centrale verwerking streven we naar maximaal circulaire resultaten. 
					Het ontwaterde GFE kan zelfs worden ingezet voor hoogwaardige producten in de toekomst. 
                  </p>
				  <h3>
				  	Werking  
				  </h3>
				  <p>
					De technische aspecten van de proefopstelling omvatten minimaal drie verschillende voedselrestenvermalers, een mobiele unit met drie gootstenen, 
					een buizenstelsel met vrij verval, een buffertank en een ontwateringsinstallatie. Monitoring op afstand zorgt voor een efficiënte werking, 
					terwijl vacuümpompen en gedoseerde watertoevoer worden beproefd om de optimale prestaties te garanderen. 
				  </p>
				  <h3>
					Samenwerking
				  </h3>
				  <p>
				  	Dit project is een samenwerking tussen AMS Institute, het Zero Waste Foundation consortium, de G4 (gemeentes Amsterdam, Utrecht, Den Haag 
					en Rotterdam), Rijkswaterstaat, Wageningen Universiteit en Research, en verschillende onderwijsinstellingen. 
				  </p>
				</div>
			  )
			  }
                </Col>
              </Row>
            </div>
			<div className="text-center my-5">
			<h5> 
			
			Wilt u meer weten over dit project, vermalers, regelgeving etc.? Voer hieronder keywords in. Onze AI zal aangeven in welke documenten u relevante informatie kunt vinden.
			<br></br>
			<br></br>
			Het zoekplatform toont uitsluitend referenties die wetenschappelijk zijn erkend.
			<br></br>
			<br></br>
			Blader door verschillende antwoorden door op de rode vierkantjes onderaan te klikken.
		</h5>
		</div>
        </Container>
	</>
	)
};

export default ProjectInformation;