import React, { useState, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Carousel,
	CarouselItem,
	CarouselIndicators,
	Card,
	CardBody,
	CardText,
	CardTitle
} from 'reactstrap';
import SearchBar from './SearchBar';
import axios from 'axios';

const AnswerCarousel = () => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [animating, setAnimating] = useState(false);
	const [carouselItems, setCarouselItems] = useState([]);
	const [keywords, setKeywords] = useState([])
	const [highlightedText, setHighlightedText] = useState('');

	const onExiting = () => {
		setAnimating(true);
	}	

	const onSearch = async (searchTerm) => {
		console.time('AI answer timing')
		try {
				const response = await axios.post('/api/ai_answer', {
				question: searchTerm
			});
			const data = await JSON.parse(response.data);
			const matchedDataArray = await Object.values(data);
			setKeywords(getKeywordCount(matchedDataArray[0]?.keywords, matchedDataArray[0]?.content)); 
			setCarouselItems(matchedDataArray);

			// Makes sure activeIndex can't be out of bounds
			setActiveIndex(Math.min(activeIndex, matchedDataArray.length - 1));
		} catch (error) {
			console.error('Error fetching data from backend:', error);
		} finally {
			console.timeEnd('AI answer timing')
		}
	};

	const getKeywordCount = (keywords, content) => {
		return keywords.map((keyword) => ({
			keyword: keyword,
			count: countOccurrences(content, keyword),
		}));
	};

	const updateKeywordCount = (index) => {
		const updatedKeywords = keywords.map((item) => ({
			keyword: item.keyword,
			count: countOccurrences(carouselItems[index].content, item.keyword),
		}));
		setKeywords(updatedKeywords);
	};

	const countOccurrences = (content, keyword) => {
		const regex = new RegExp(keyword, 'gi');
		const matches = content.match(regex);
		return matches ? matches.length : 0;
	};

	const onExited = () => {
		setAnimating(false);
	};

	const next = () => {
		if (animating) 
			return;
		const nextIndex = activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
		setActiveIndex(nextIndex);
	};

	const previous = () => {
		if (animating) 
			return;
		const nextIndex = activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
		setActiveIndex(nextIndex);
	};

	const goToIndex = async (newIndex) => {
		if (animating) 
			return;
		await updateKeywordCount(newIndex);
		setActiveIndex(newIndex);
	};

	const handleCardClick = (textToHighlight) => {
		setHighlightedText(textToHighlight);
	};

	return (
		<>
			<div className="section" id="carousel">
			<Container>
    			<SearchBar onSearch={onSearch} />
    			<br/> <br/> <br/>
    			<Row className="justify-content-center">
    			    <Col lg="8" md="12">
    			        <Carousel
    			            interval={120000000}
    			            activeIndex={activeIndex}
    			            next={next}
    			            previous={previous}
    			        >
    			        <CarouselIndicators
    			            items={carouselItems}
    			            activeIndex={activeIndex}
    			            onClickHandler={goToIndex}
    			        />
    			        {carouselItems && carouselItems.map((item, index) => (
    			            <CarouselItem
    			                onExiting={onExiting}
    			                onExited={onExited}
    			                key={index}
    			            >
								<div className="carousel-caption d-none d-md-block" 
								    style={{ 
								        color: 'black', 
								        display: 'flex', 
								        flexDirection: 'column', 
								        alignItems: 'flex-start', // This should align text to the left
								        width: 'auto', 
								        height: '100%', 
								        overflow: 'auto', 
								        whiteSpace: 'pre-wrap' 
								}}>
    			                    <h5><br/>Gevonden op pagina {item.page_number} van het document '<b>{item.document}</b>'</h5>
    			                    <br />
    			                    <div dangerouslySetInnerHTML={{ __html: highlightAnswer(item.content, highlightedText) }} />
    			                </div>
    			                <img 
    			                    className='carousel-background-image'
    			                    src={require('../assets/img/leaf_frame.png')} 
    			                    alt="" 
    			                />
    			            </CarouselItem>
    			        ))}    
    			        </Carousel>
    			    </Col>
    			    <Col lg="4" md="4" sm="12">
    			        {keywords &&
    			        keywords.map((item, index) => (
    			            <Card
							key={index}
    			            className="text-center"
    			            style={{ 
    			                cursor: 'pointer',
    			                color: '#228B22',
    			                boxShadow: '4px 6px 28px 5px rgba(0,100,0,0.6)',
    			                borderRadius: '4vw',
    			            }}
    			            onClick={() => handleCardClick(item.keyword)}
    			            >
    			                <CardBody>
    			                    <CardTitle>Klik om keyword te highlighten</CardTitle>
    			                    <CardText><strong>'{item.keyword}'</strong> {'('}{item.count}x{')'}</CardText>
    			                </CardBody>
    			            </Card>
    			        ))}
    			    </Col>
    			</Row>
    			<br/> <br/> <br/>
			</Container>
			</div>
		</>
	);
};

const highlightAnswer = (content, answer) => {
	return content.replace(new RegExp(answer, 'gi'), match => `<span style="background-color: yellow">${match}</span>`);
};

export default AnswerCarousel;
