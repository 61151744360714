// Instructions.js
import React, { useState } from 'react';

import { Container, Col } from 'reactstrap';

const Instructions = () => {
	const [isCollapsed, setIsCollapsed] = useState(true);

	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed);
	}

	return (
	<>
		<div className="text-center my-5">
		<h5 onClick={toggleCollapse} style={{ cursor: 'pointer'}}>
			<u>Klik hier</u> voor uitgebreide instructies.
		</h5>
		{!isCollapsed && (
			<div> 
			<Container>
				<Col>
					<p>
					Volg onderstaande richtlijnen indien u verdere vragen heeft of meer informatie wenst. 
					Onze AI zal grondig alle relevante documenten doorzoeken en proberen uw vraag te beantwoorden 
					door aan te geven in welke delen van de documenten het antwoord mogelijk te vinden is. 
					Voor uw gebruiksgemak kunt u moeiteloos keywords selecteren, die vervolgens oplichten 
					in de ontdekte tekst. Daarnaast treft u onderaan de tekst de naam van het document en 
					het paginanummer waar het antwoord is gelokaliseerd.
					</p>
				</Col>
			</Container>
			<ul className="list-unstyled text-center">
			<li>
			<strong>1. Vragen stellen:</strong>
			<p>
				Gebruik de zoekbalk hieronder om uw vraag in te voeren. Of typ belangrijke keywords die 
				verband houden met uw interesse.
			</p>
			</li>

			<li>
			<strong>2. Bekijk de antwoorden:</strong>
			<p>
				Ontdek relevante antwoorden in de interactieve carrousel die zal verschijnen onder de zoekbalk. 
				Blader door de antwoorden door op de pijlen te klikken.
			</p>
			</li>

			<li>
			<strong>3. Ontdek keywords:</strong>
			<p>
				Boven de antwoorden ziet u keywords. Als u op een keyword klikt zal het in de text gehighlight worden.
			</p>
			</li>

			<li>
			<strong>4. Navigeer met gemak:</strong>
			<p>
				Gebruik de zoekbalk en keywords om snel door de antwoorden te navigeren. De gele 
				highlight maakt de belangrijkste informatie gemakkelijk te vinden.
			</p>
			</li>

			<li>
			<strong>5. Probeer verschillende vragen en keywords:</strong>
			<p>
				Experimenteer met verschillende vragen en keywords om een breed scala aan relevante 
				informatie te verkennen.
			</p>
			</li>
		</ul>

		<p>
			Bedankt voor het gebruik van onze website! Als u verdere vragen heeft, aarzel dan niet om 
			contact met ons op te nemen.
		</p>
		</div>
		)}
		</div>
	</>
	);
};

export default Instructions;
