import React from 'react';
import EditKeywords from './EditKeywords.js';
import EditQuestions from './EditQuestions';

const EditPage = () => {
  return (
    <div>
      {/* <EditKeywords /> */}
      <EditQuestions />
    </div>
  );
};

export default EditPage;