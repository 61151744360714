import React from "react";
import {
	Row,
	Col
} from "reactstrap";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FoundingFathers from "components/founding_fathers";
import Team from "components/team";
import ProjectInformation from "components/project_information";

import AnswerCarousel from "components/AnswerCarousel";
import Instructions from "components/Instructions";

function AmsPage() {

	React.useEffect(() => {
		document.body.classList.add("landing-page");
		document.body.classList.add("sidebar-collapse");
		document.documentElement.classList.remove("nav-open");
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
		return function cleanup() {
			document.body.classList.remove("landing-page");
			document.body.classList.remove("sidebar-collapse");
		};
	}, []);

	return (
		<>
			<div className="wrapper">
				<LandingPageHeader />
				<ProjectInformation />
				<Row className="justify-content-center">
  					<Col className="col-12 col-md-8">
						<AnswerCarousel/>
					</Col>
				</Row>
				<Instructions />
				<FoundingFathers />
				<Team />
			</div>
		</>
	);
}

export default AmsPage;
