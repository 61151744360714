import React, { useState } from 'react';
import axios from 'axios';

export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const submit = async e => {
    e.preventDefault();
    const user = {
      username: username,
      password: password
    };
    try {
      const { data } = await axios.post('/api/token', user, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      });
		  localStorage.clear();
		  localStorage.setItem('access_token', data.access);
		  localStorage.setItem('refresh_token', data.refresh);
		  axios.defaults.headers.common['Authorization'] = `Bearer ${data.access}`;
		  window.location.href = '/upload';
    } catch (error) {
        setError('Invalid username or password');
    }
  }

  return (
    <div className="Auth-form-container">
      {error && <div className="alert alert-danger" role="alert">{error}</div>}
      <form className="Auth-form" onSubmit={submit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Username</label>
            <input className="form-control mt-1"
              placeholder="Enter Username"
              name='username'
              type='text'
              value={username}
              required
              onChange={e => setUsername(e.target.value)} />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input name='password'
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              value={password}
              required
              onChange={e => setPassword(e.target.value)} />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit"
              className="btn btn-primary">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}


// import axios from "axios";
// import {useState} from "react";

// export const Login = () => {
// 	const [username, setUsername] = useState('');
// 	const [password, setPassword] = useState('');

// 	const submit = async e => {
// 		e.preventDefault();
// 		const user = {
// 			username: username,
// 			password: password
// 		};
// 		const {data} = await																			
// 			axios.post('/api/token',
// 			user ,{headers: 
// 			{'Content-Type': 'application/json'},
// 			 withCredentials: true});
	
// 			console.log('data:', data);

// 		 localStorage.clear();
// 		 localStorage.setItem('access_token', data.access);
// 		 localStorage.setItem('refresh_token', data.refresh);
// 		 axios.defaults.headers.common['Authorization'] = 
// 										 `Bearer ${data['access']}`;
// 		 window.location.href = '/upload';
// 	}
// 	return(
// 		<div className="Auth-form-container">
// 		<form className="Auth-form" onSubmit={submit}>
// 			<div className="Auth-form-content">
// 			<h3 className="Auth-form-title">Sign In</h3>
// 			<div className="form-group mt-3">
// 				<label>Username</label>
// 				<input className="form-control mt-1" 
// 				placeholder="Enter Username" 
// 				name='username'	
// 				type='text' value={username}
// 				required 
// 				onChange={e => setUsername(e.target.value)}/>
// 			</div>
// 			<div className="form-group mt-3">
// 				<label>Password</label>
// 				<input name='password' 
// 				type="password"	 
// 				className="form-control mt-1"
// 				placeholder="Enter password"
// 				value={password}
// 				required
// 				onChange={e => setPassword(e.target.value)}/>
// 			</div>
// 			<div className="d-grid gap-2 mt-3">
// 				<button type="submit" 
// 				 className="btn btn-primary">Submit</button>
// 			</div>
// 			</div>
// 		 </form>
// 	 </div>
// 	 )
// }
