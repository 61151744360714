import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import AIAnswering from './ai_answering.js'
import KeywordAnswering from './keyword_answering.js';
import ForwardQuestion from './forward_question.js';

const theme = {
	background: '#f5f8fb',
	fontFamily: 'Helvetica Neue',
	headerBgColor: '#328055',
	headerFontColor: '#fff',
	headerFontSize: '15px',
	botBubbleColor: '#328055',
	botFontColor: '#fff',
	userBubbleColor: '#fff',
	userFontColor: '#4a4a4a',
};

KeywordAnswering.propTypes = {
	steps: PropTypes.object,
	
};

KeywordAnswering.defaultProps = {
	steps: undefined,
};

AIAnswering.propTypes = {
	steps: PropTypes.object,
	
};

AIAnswering.defaultProps = {
	steps: undefined,
};

class AMSChatBot extends Component {
	state = {
		opened: false
	}

 	render() {
		const { opened } = this.state;
		return (
			<ThemeProvider theme={theme}>
			<ChatBot
				botAvatar={require("../../assets/img/eco_bot.png")}
				hideUserAvatar={true}
			 	floating={true}
				opened={opened}
				toggleFloating={this.toggleFloating}
			 	steps={[
					{
						id: '1',
						message: 'Welkom bij de Chatbot voor het project Proefopstelling met Vermalers! 🌱 Heeft u vragen over ons project? We staan klaar om te helpen!',
						trigger: 'ask-question',
					},
					{
						id: 'ask-question',
						message: 'Wat is uw vraag',
						trigger: 'question',
					},
					{
						id: 'question',
						user: true,
						validator: (value) => {
							return value ? true : 'Please enter a valid question.';
						},
						trigger: 'answering',
					},
					{
						id: 'answering',
						component: <AIAnswering />,
						waitAction: true,
						trigger: 'is-question-answered-msg',
					},
					{
						id: 'is-question-answered-msg',
						message: 'Is uw vraag beantwoord?',
						trigger: 'is-question-answered-options'
					},
					{
						id: 'is-question-answered-options',
						options: [
							{ value: 'ja', label: 'Ja', trigger: 'another-question-msg' },
							// { value: 'nee', label: 'Nee', trigger: 'keyword-answer' },
							{ value: 'nee', label: 'Nee', trigger: 'forward-question-action' },
						]
					},
					{
						id: 'keyword-answer',
						component: <KeywordAnswering/>,
						waitAction: true,
						trigger: 'is-question-answered-final-msg',
					},
					{
						id: 'is-question-answered-final-msg',
						message: 'En is uw vraag nu beantwoord?',
						trigger: 'is-question-answered-final-options',
					},
					{
						id: 'is-question-answered-final-options',
						options: [
							{ value: 'ja', label: 'Ja', trigger: 'another-question-msg' },
							{ value: 'nee', label: 'Nee', trigger: 'forward-question-action' },
						]
					},
					{
						id: 'forward-question-action',
						component: < ForwardQuestion/>,
						trigger: 'forward-question-msg' 
					},
					{
						id: 'forward-question-msg',
						message: 'Wat vervelend dat uw vraag nog niet beantwoord is. Ik zal de vraag ter beantwoording doorsturen naar een van onze onderzoekers, zodat zij deze kunnen beantwoorden en het antwoord binnenkort beschikbaar zal zijn in onze vragen-database.',
						// trigger: 'leave-email', // FIXME: optional add a leave email disclaimer etc
						trigger: 'another-question-msg',
					},
					// {
					// 	id: 'leave-email',
					// 	options: 'Wilt u uw email-adres achterlaten zodat wij u eenmalig kunnen benaderen?'
					// }
					{
						id: 'another-question-msg',
						message: 'Wilt u nog een vraag stellen?',
						trigger: 'another-question-options',
					},
					{
						id: 'another-question-options',
						options: [
							{ value: 'ja', label: 'Ja', trigger: 'ask-question' },
							{ value: 'nee', label: 'Nee', trigger: 'bye' },
						],
					},
					{
						id: 'bye',
						message: 'Dank voor uw bezoek en een fijne dag toegewenst!',
						end: true,
					},
				]}
			/>
		</ThemeProvider>
		);
	}
}

export default AMSChatBot;
