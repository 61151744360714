import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditQuestions = () => {
	const [questions, setQuestions] = useState([]);
	const [newQuestion, setNewQuestion] = useState('');
	const [newAnswer, setNewAnswer] = useState('');
	const [newKeywords, setNewKeywords] = useState('');
	const [editQuestion, setEditQuestion] = useState('');
	const [editQuestionId, setEditQuestionId] = useState('');

	// const [editQuestion, setEditQuestion] = useState('');
	const [editAnswer, setEditAnswer] = useState('');
	const [editKeywords, setEditKeywords] = useState('');
	// const [editQuestion, setEditQuestion] = useState(null);

	useEffect(() => {
		// Fetch Questions from the Django backend when the component mounts
		const fetchQuestions = async () => {
			try {
			const response = await axios.get('http://localhost:8000/api/fetch_questions/');
			setQuestions(response.data);
			} catch (error) {
			console.error('Error fetching Questions:', error);
			}
		};

		fetchQuestions();

		setNewQuestion('');
		setNewAnswer('');
		setNewKeywords('');
		setEditQuestion('');
	}, []); // Empty dependency array means this effect runs once when the component mounts

	const handleAddQuestion = async () => {
		if (!newQuestion || !newAnswer) {
   			alert('Please enter both question and answer.');
    	return;
 	 	}
		try {
			// Make a POST request to add a new question
			const result = await axios.post('http://localhost:8000/api/add_questions/', {
				question: newQuestion,
				answer: newAnswer,
				keywords: newKeywords.split(',').map(keyword => keyword.trim()),
			});
			alert (result.data.message)
			
			// Refresh the questions list after adding a new question
			const response = await axios.get('http://localhost:8000/api/fetch_questions/');
			setQuestions(response.data);
			// Clear the input fields
			setNewQuestion('');
			setNewAnswer('');
			setNewKeywords('');
		} catch (error) {
			console.error('Error adding question:', error);
		}
	};

	const handleEditQuestion = async () => {
		if (editQuestion) {
			try {
			// Make a PUT request to update the selected question
			const result = await axios.put(`http://localhost:8000/api/edit_question/${editQuestionId.id}/`, {
				question: editQuestion || editQuestion.question,
				answer: editAnswer || editQuestion.answer,
				keywords: editKeywords.split(',').map(keyword => keyword.trim()),
			});
			alert(result.data['message'])
	
			const response = await axios.get('http://localhost:8000/api/fetch_questions/');
			setQuestions(response.data);
			setEditQuestion('');
			setEditAnswer('');
			setEditKeywords('');
			setEditQuestion('');
			} catch (error) {
			console.error('Error editing question:', error);
			}
		}
	};
	
	const handleRemoveQuestion = async (id) => {
		try {
			// Make a DELETE request to remove the selected question
			const result = await axios.delete(`http://localhost:8000/api/remove_question/${id}/`);
			alert(result.data['message']);

			const response = await axios.get('http://localhost:8000/api/fetch_questions/');
			setQuestions(response.data);
		} catch (error) {
			console.error('Error removing question:', error);
		}
	};
	
	// const handleStartEdit = (question) => {
	// 	// Set the selected question for editing
	// 	setEditQuestion(question);
	// 	setNewQuestion(question.question);
	// 	setNewAnswer(question.answer);
	// 	setNewKeywords(question.keywords?.join(', '));
	// };
	
	const handleStartEdit = (question) => {
		// Set the selected question for editing
		setEditQuestionId(question);
		setEditQuestion(question.question);
		setEditAnswer(question.answer);
		setEditKeywords(question.keywords?.join(', '));
		// setNewQuestion(question.question);
		// setNewAnswer(question.answer);
		// setNewKeywords(question.keywords?.join(', '));
		// Clear the edit state
		// setEditQuestion(null); Move this line outside the handleStartEdit function
	};

	const handleCancelEdit = () => {
		// Clear the selected question and input fields
		setEditQuestion('');
		setNewQuestion('');
		setNewAnswer('');
		setNewKeywords('');
	};

	return (
		<div>
		<h2>Questions</h2>
		<ul>
		{questions?.map((question) => (
          <li key={question.id}>
            <strong>{question.question}</strong> - {question.answer} - Keywords:{' '}
            {Array.isArray(question.keywords) ? question.keywords.join(', ') : question.keywords}
            <button onClick={() => handleStartEdit(question)}>Edit</button>
            <button onClick={() => handleRemoveQuestion(question.id)}>Remove</button>
          </li>
        ))}
		</ul>

		<h3>Add question</h3>
		<div>
		<label>Question:</label>
		<input type="text" value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)} />
		</div>
		<div>
		<label>Answer:</label>
		<input type="text" value={newAnswer} onChange={(e) => setNewAnswer(e.target.value)} />
		</div>
		<div>
		<label>Keywords (comma-separated):</label>
		<input type="text" value={newKeywords} onChange={(e) => setNewKeywords(e.target.value)} />
		</div>
		<button onClick={handleAddQuestion}>Add Question</button>

		<h3>Edit question</h3>
		<div>
		<label>Question:</label>
		<input type="text" value={editQuestion} onChange={(e) => setEditQuestion(e.target.value)} />
		</div>
		<div>
		<label>Answer:</label>
		<input type="text" value={editAnswer} onChange={(e) => setEditAnswer(e.target.value)} />
		</div>
		<div>
		<label>Keywords (comma-separated):</label>
		<input type="text" value={editKeywords} onChange={(e) => setEditKeywords(e.target.value)} />
		</div>
		<button onClick={handleEditQuestion}>Edit Question</button>
	</div>
	);
};

export default EditQuestions;