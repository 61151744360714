import React from "react";
import { Container, Row, Col } from "reactstrap";

function Team () {
	return (
		<div className="section section-team text-center">
		<Container>
			<h4 className="title">The project team</h4>
			<div className="team">
			<Row>
				<Col md="4">
				<div className="team-player">
					<img
					alt="..."
					className="rounded-circle img-fluid img-raised"
					src={require("assets/img/profile_pic_willie.jpg")}
					></img>
					<h5 className="title">Willie van den Broek</h5>
					<p className="category text-info">Project Manager</p>
					<p className="description">
						You can write here details about one of your team members.
					</p>
				</div>
				</Col>
			</Row>
			</div>
		</Container>
		</div>
	)
}

export default Team;