import React, { useState, useEffect } from 'react';

export function Navigation() {
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('access_token') !== null) {
      setIsAuth(true);
    }
  }, [isAuth]);

  return (
    <div>
      <nav>
        {isAuth ? (
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/logout">Logout</a>
            </li>
          </ul>
        ) : (
          <ul>
            <li>
              <a href="/login">Login</a>
            </li>
          </ul>
        )}
      </nav>
    </div>
  );
}